import React from 'react';
import {Link} from 'gatsby';
import {navigate} from 'gatsby'; // import navigate from gatsby
import {Helmet} from 'react-helmet';
import {Event} from '../GAClickEvent';
import OurLeadership from './OurLeadership';
import OurClient from './OurClient';
import Journey from '../Home/journey';
import WhyUs from './WhyUs';

import ClientTestimonialCard from './ClientTestimonial/ClientTestimonialCard';
import HeadingBanner from '../Shared/HeadingBanner/HeadingBanner';

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>About Us | Tintash</title>
    </Helmet>
    <div>
      {/* Company Page Banner */}
      <HeadingBanner
        heading="About Us"
        description="Since 2008, we’ve been committed to providing dedicated remote teams to engineer finest digital experiences"
        imageSrc={require('../../assets/images/company/topbanner-bg.jpg').default}
      />
      {/* Company Page Banner  */}
      {/* Our Story */}
      <section className="company-page-story py-5 my-2">
        <div className="container">
          <div className="row justify-content-center align-items-center py-1">
            <div className="col-12">
              <h1 className="latoBlack text-left heading pb-2">Our Story</h1>
              <p className="latoRegular text-left description mt-2 mb-4">
                Tintash is a tech design and dev studio where talented, skilled and experienced
                teams are led by Stanford University, Apple, and Paypal Alumni.
              </p>
              <p className="latoRegular text-left description mb-4">
                It all started in 2007 with a breakfast at Hobee’s Palo Alto. Over the famous coffee
                cake the founders decided to prototype working together. It worked, and to this date
                when we have a new client company we first prototype working with them, customize a
                work and communication process that is a win-win, and almost always evolve into an
                ongoing working relationship.
              </p>
              <p className="latoRegular text-left description mb-4">
                We specialize in providing dedicated remote team members to aspiring entrepreneurs,
                promising startups, and growing companies. Our goal is to become a preferred top
                tier tech partner for other companies looking to grow with a remote team.
              </p>
              <p className="latoRegular text-left description mb-0">
                The teams at the studio have been growing since 2008 and love working on challenging
                and world impacting products. We hire people who are really good at what they do but
                also would get along like a house on fire with our team. Our culture is our key
                differentiator and is heavily influenced by Stanford d.school, where the founders
                were trained, and IDEO, the company we love.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Our Story */}
      {/* Why Us Section */}
      <section className="company-different-section py-5">
        <WhyUs />
      </section>
      {/* Client Say about us */}
      <section className="company-client-section pt-2 pb-5">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 pull-left">
              <h1 className="latoBlack text-left main-heading mb-5">What Clients Love About Us</h1>
              <div className="row">
                <ClientTestimonialCard
                  title={
                    <>
                      Stellar <br /> Communication
                    </>
                  }
                  description={
                    <>
                      Tintash understood our <br /> needs, and they were easy <br /> to communicate
                      with.
                    </>
                  }
                  name="Lionel Koh"
                  position="Director, Askvisor"
                  imgSrc={
                    require('../../assets/images/company/icons/whyus/stellar-communication.svg')
                      .default
                  }
                />

                <ClientTestimonialCard
                  title={
                    <>
                      Visibility and <br />
                      Transparency
                    </>
                  }
                  description={
                    <>
                      They excel at best practices. We went in not knowing too much about iOS
                      development, so we trusted them to use the best practices to optimize the app.
                    </>
                  }
                  name="Forfit"
                  position="Co-founder"
                  imgSrc={
                    require('../../assets/images/company/icons/whyus/visibility-and-transparency.svg')
                      .default
                  }
                />

                <ClientTestimonialCard
                  title={<>Delivery Process</>}
                  description={
                    <>
                      They were flexible and adaptable to the problem and issues encountered, and
                      very resourceful when dealing with issues; Extremely committed to delivering a
                      solid product.
                    </>
                  }
                  name="Patrick Cosgrove"
                  position="CTO, Lifeprint"
                  imgSrc={
                    require('../../assets/images/company/icons/whyus/delivery-process.svg').default
                  }
                />

                <ClientTestimonialCard
                  title={
                    <>
                      Iterate Towards <br /> Excellence
                    </>
                  }
                  description={
                    <>
                      I think the most impressive thing is the ratio of professionalism to cost is
                      very high.
                    </>
                  }
                  name="David Cohen-Tanugi"
                  position="Co-Founder, Embr Labs"
                  imgSrc={
                    require('../../assets/images/company/icons/whyus/iterate-towards-excellence.svg')
                      .default
                  }
                />

                <ClientTestimonialCard
                  title={
                    <>
                      Ownership and <br /> Responsibility
                    </>
                  }
                  description={
                    <>
                      Tintash Apps reliable workflow, helpful feedback, and long-term focus
                      contributed to a rewarding partnership.
                    </>
                  }
                  name="Basar Simitci"
                  position="CTO, Joyful Works"
                  imgSrc={
                    require('../../assets/images/company/icons/whyus/ownership-and-individual-responsibility.svg')
                      .default
                  }
                />

                <div className="col-lg-4 col-sm-12 col-md-6 mb-4 pb-1 d-flex ">
                  <div
                    className="card about-us get-started border-0 p-4 justify-content-center align-items-center w-100"
                    onClick={() => navigate('/contact')}
                    style={{cursor: 'pointer'}}
                  >
                    <p className="latoBlack text-center text-md-left heading px-0 px-md-5 mw-100">
                      Looking for such a team? We should talk.
                    </p>
                    <div>
                      <Link
                        className="btn btn btn-outline-success btn-started px-5 mt-2"
                        onClick={() =>
                          Event('Get Started', 'Get Started is clicked', window.location.pathname)
                        }
                        to="/contact"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Client Say about us */}
      {/* Number Section Code */}
      <section className="about-us-journey">
        <Journey white />
      </section>
      {/* Number Section Code Complete */}
      {/* Leadership section  */}
      <section className="leadership-section py-5 my-3">
        <OurLeadership />
      </section>
      {/* Leadership section Complete */}
      {/* Our Values */}
      <section className="values-section py-5">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 my-2">
              <h1 className="latoBlack text-left heading mb-5">Our Values</h1>
              <div className="values-container row">
                <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                  <img
                    src={
                      require('../../assets/images/company/icons/our-values/new/faith.png').default
                    }
                  />
                  <p className="text-center pt-3  values">Have Faith</p>
                </div>
                <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                  <img
                    src={
                      require('../../assets/images/company/icons/our-values/new/prototype.png')
                        .default
                    }
                  />
                  <p className="latoRegular text-right pt-3  values">Prototype</p>
                </div>
                <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                  <img
                    src={
                      require('../../assets/images/company/icons/our-values/new/character.png')
                        .default
                    }
                  />
                  <p className="text-center pt-3  values">Akhlaq</p>
                </div>
                <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                  <img
                    src={
                      require('../../assets/images/company/icons/our-values/new/enthusiasm.png')
                        .default
                    }
                  />
                  <p className="text-center pt-3  values">Shokunin</p>
                </div>
                <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                  <img
                    src={
                      require('../../assets/images/company/icons/our-values/new/proactive.png')
                        .default
                    }
                  />
                  <p className=" text-center pt-3  values">Be Proactive</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our Values */}
      {/* Client Logos */}
      <section className="client-logos py-5">
        <OurClient />
      </section>
      {/* Client Logos */}
    </div>
  </>
);
