import React from 'react';

export default function WhyUsCard({imgSrc, title, description}) {
  return (
    <div className="col-lg-6 col-sm-12 col-md-6 mb-4 pb-1 d-flex">
      <div className="card shadow-sm py-4 px-5 about-us w-100">
        <div className="info">
          <img className="img-fluid" width="64px" src={imgSrc} />
          <h3 className="latoBlack heading text-left pt-2">{title}</h3>
        </div>
        <div className="details">{description}</div>
      </div>
    </div>
  );
}
