import React from 'react';
import LinkedinCard from './LinkedinCard/LinkedinCard';
import Tooltip from '../../UI/Tooltip';

export default () => (
  <div className="container">
    <div className="row justify-content-center align-items-center">
      <div className="col-12">
        <h1 className="text-left latoBlack heading mb-3">Our Leadership</h1>
        <p className="latoRegular description mb-5">
          Together we are smarter than each of us. We disagree passionately, learn continuously, and
          commit relentlessly as we lead our team towards becoming the most preferred tech partner
          for our growing set of clients.
        </p>
        <div className="about-our-leaders__grid--top">
          <LinkedinCard
            name="Murad Akhter"
            position="CEO &amp; Co-Founder"
            linkedinURL="https://www.linkedin.com/in/muradakhter/"
            imgSrc="murad-akhter.jpg"
            eventName="Murad Akhter LinkedIn"
            eventValue="Murad Akhter Profile on LinkedIn"
          />

          <LinkedinCard
            name="William Schleifer"
            position="VP Client Engagement, North America"
            linkedinURL="https://www.linkedin.com/in/williamschleifer/"
            imgSrc="william-schleifer.jpg"
            eventName="William Schleifer LinkedIn"
            eventValue="William Schleifer Profile on LinkedIn"
          />

          <LinkedinCard
            name="Syed Pirzada"
            position="GM Canada"
            linkedinURL="https://www.linkedin.com/in/syedpirzada/"
            imgSrc="syed-pirzada.jpg"
            eventName="Syed Pirzada LinkedIn"
            eventValue="Syed Pirzada Profile on LinkedIn"
          />

          <LinkedinCard
            name="Fahd Gilani"
            position="SVP Engineering"
            linkedinURL="https://www.linkedin.com/in/fgilani/"
            imgSrc="fahad-gilani.jpg"
            eventName="Fahd Gilani LinkedIn"
            eventValue="Fahd Gilani Profile on LinkedIn"
          />

          <LinkedinCard
            name="Faried Nawaz"
            position="CTO"
            linkedinURL="https://www.linkedin.com/in/fariednawaz/"
            imgSrc="faried-nawaz.jpg"
            eventName="Faried Nawaz LinkedIn"
            eventValue="Faried Nawaz Profile on LinkedIn"
          />

          <LinkedinCard
            name="Abdul Wasay"
            position="VP Engineering"
            linkedinURL="https://www.linkedin.com/in/a-wasay/"
            imgSrc="abdul-wasay.jpg"
            eventName="Abdul Wasay LinkedIn"
            eventValue="Abdul Wasay Profile on LinkedIn"
          />

          <LinkedinCard
            name="Faizan Rasool"
            position="VP Marketing &amp; Sales"
            linkedinURL="https://www.linkedin.com/in/faizan-rasool-93b74aa/"
            imgSrc="faizan-rasool.jpg"
            eventName="Faizan Rasool LinkedIn"
            eventValue="Faizan Rasool Profile on LinkedIn"
          />
          <LinkedinCard
            name="Mohammed Ali"
            position="VP Hardware"
            linkedinURL="https://www.linkedin.com/in/mdotali/"
            imgSrc="m-ali.jpg"
            eventName="Mohammed Ali LinkedIn"
            eventValue="Mohammed Ali Profile on LinkedIn"
          />
        </div>
        <div className="about-our-leaders__grid--bottom">
          <LinkedinCard
            name="Jazib Zahir"
            position="Senior Partner"
            linkedinURL="https://www.linkedin.com/in/jazib-zahir-b629513/"
            imgSrc="jazib-zahir.jpg"
            eventName="Jazib Zahir LinkedIn"
            eventValue="Jazib Zahir Profile on LinkedIn"
          />

          <LinkedinCard
            name="Mannan Amin"
            position="Co-Founder and President"
            linkedinURL="https://www.linkedin.com/in/mannanamin/"
            imgSrc="mannan-amin.jpg"
            eventName="Mannan Amin LinkedIn"
            eventValue="Mannan Amin Profile on LinkedIn"
          />
        </div>
      </div>
    </div>
  </div>
);
