import React from 'react';
import WhyUsCard from './WhyUsCard';

export default function index() {
  return (
    <div className="container py-2">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 pull-left">
          <h1 className="latoBlack text-left main-heading mb-5">What Makes Us Different</h1>
          <div className="row">
            <WhyUsCard
              imgSrc={require('../../../assets/images/company/icons/whyus/team.svg').default}
              title="Stanford, Harvard, Apple &amp; Paypal Alumni Leadership"
              description="Combining their experience working for industry giants such as
                  Apple and Paypal, Tintash is a team led by Stanford and
                  Harvard Alumni that aims to bring the best of Silicon Valley
                  to you."
            />
            <WhyUsCard
              imgSrc={require('../../../assets/images/company/icons/whyus/star.svg').default}
              title="Proven Track Record with 10+ Years In the Industry"
              description="Since 2008, we’ve worked with over 130+ clients, ranging from
              startups to Fortune 500’s. Our clients have raised over $750M
              in funding, with some becoming unicorns that disrupted their
              industries."
            />
            <WhyUsCard
              imgSrc={require('../../../assets/images/company/icons/whyus/cost.svg').default}
              title="Significant Cost Advantage"
              description="Our global teams strive for the simple target of helping you
              achieve more for less by bringing you onshore quality work at
              offshore rates."
            />

            <WhyUsCard
              imgSrc={
                require('../../../assets/images/company/icons/whyus/communication.svg').default
              }
              title="Top Tier Communication"
              description="Our global teams mean we’re always aligned on time zones with
              our client and, along with the high english proficiency of
              our engineers, allows for extremely efficient development and
              management cycles for rapid product delivery rates."
            />
          </div>
        </div>
      </div>
    </div>
  );
}
