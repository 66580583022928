import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Event} from '../../../GAClickEvent';
import * as classes from './LinkedinCard.module.css';

export default function LinkedinCard({linkedinURL, eventName, eventValue, imgSrc, name, position}) {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativeDirectory: {eq: "company/our-leaders"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              fixed(width: 179, height: 179, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);

  const match = React.useMemo(
    () =>
      data.allFile.edges.find(({node}) => `company/our-leaders/${imgSrc}` === node.relativePath),
    [data, imgSrc]
  );

  return (
    <div
      className={`card ${classes.memberCard}`}
      onClick={() => {
        window.location.href = linkedinURL;
        Event(eventName, eventValue, window.location.pathname);
      }}
    >
      <div className={classes.memberImageContainer}>
        <Img
          className="img-fluid"
          imgStyle={{objectFit: 'contain'}}
          placeholderStyle={{objectFit: 'contain'}}
          fixed={match.node.childImageSharp.fixed}
          alt={name}
          fadeIn={false}
          loading="lazy"
        />
      </div>
      <div className="card-body text-center">
        <h5 className={classes.name}>{name}</h5>
        <p className={classes.designation}>{position}</p>
        <a href={linkedinURL}>
          <img
            className={classes.linkedinImage}
            src={require('../../../../assets/images/company/linkedin.svg').default}
            alt="Linkedin Logo"
          />
        </a>
      </div>
    </div>
  );
}
