import React from 'react';

export default function ClientTestimonialCard({title, description, name, position, imgSrc}) {
  return (
    <div className="col-lg-4 col-sm-12 col-md-6 mb-4 pb-1 d-flex ">
      <div className="card border-0 py-4 px-5 about-us w-100">
        <img className="img-fluid" width="70px" src={imgSrc} />
        <h3 className="latoBlack text-left heading pt-2">{title}</h3>
        <div className="card-body p-0">
          <div className="background mt-1 ">
            <p className="description pt-2">{description}</p>
          </div>
        </div>
        <div className="card-footer bg-transparent border-0 pl-0">
          <div className="mt-2 description">
            <span style={{color: '#363B43'}}>{name},</span>
            <br />
            <span style={{fontSize: '15px'}}>{position}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
